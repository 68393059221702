<template lang="pug">
AppCard
  .payment-status
    i(:class="currentIcon")
    h2 {{ title }}
    p {{ subtitle  }}
    div
      slot
    router-link(:to="{ name: 'Login' }" v-slot="{ navigate }" custom)
      AppButton(:label="labelValue" @click="navigate")
</template>

<script>
import AppCard from "@/components/AppCard";
import AppButton from "@/components/AppButton";
import { computed } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";

export default {
  components: { AppCard, AppButton },
  props: {
    title: String,
    subtitle: String,
    icon: {
      type: String,
      default: "check-circle",
    },
    label: {
      type: String,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const labelValue = computed(() =>
      props.label ? props.label : t("payment.success_button")
    );
    const currentIcon = computed(() => {
      return `uil uil-${props.icon}`;
    });
    return { currentIcon, labelValue };
  },
};
</script>

<style lang="scss" scoped>
.payment-status {
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply justify-center;
  i {
    @apply text-gignetgreen-400;
    @apply text-7xl;
  }
  h2 {
    @apply text-gignetgreen-400;
    @apply font-semibold;
    @apply mb-2;
    @apply text-center;
  }
  p {
    @apply text-bluegray-400;
    @apply text-sm;
    @apply text-center;
    @apply mb-8;
  }
}
</style>
